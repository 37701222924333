import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// xs: "360px",
// sm: "480px",
// md: "768px",
// lg: "1024px",
// xl: "1280px",
// "2xl": "1600px",
// "3xl": "1920px",

// const isDesktop = () => window.innerWidth >= 480;

const projectswiper = new Swiper(".projectSwiper", {
  autoplay: {
    enabled: false,
    delay: 3000
  },
  slidesPerView: 1,
  loop: true,
  autoHeight: true,
  spaceBetween: 30,
  effect: "cards",
  perSlideOffset: 2,
  perSlideRotate: 0,
  rotate: false,
  slideShadows: false,
  navigation: {
    nextEl: ".project-button-next",
    prevEl: ".project-button-prev"
  }

  // TODO: Add custom animations for desktop
});

// window.addEventListener("resize", () => {
//   if (isDesktop()) {
//     projectswiper.update(); // Update Swiper when resizing to desktop
//   }
// });

const news_swiper = new Swiper(".news-carousel", {
  autoplay: {
    enabled: false
    // delay: 6000,
  },
  loop: true,
  speed: 100,
  slidesPerView: 1.25,
  autoHeight: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  spaceBetween: 20,
  a11y: {
    prevSlideMessage: "Previous slide",
    nextSlideMessage: "Next slide"
  },
  grabCursor: false,
  draggable: false,
  breakpoints: {
    768: {
      slidesPerView: 1.25,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2.5,
      spaceBetween: 30,
    },
    1280: {
      slidesPerView: 3.5,
      spaceBetween: 50,
    },
    1600: {
      slidesPerView: 3.5,
      spaceBetween: 50,
    }
  }
});

new Swiper(".partner-carousel", {
  autoplay: {
    enabled: true,
    // delay: 6000,
  },
  loop: true,
  slidesPerView: 1.5,
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 50,
    },
    1280: {
      slidesPerView: 4,
      spaceBetween: 50,
    },
    1600: {
      slidesPerView: 5,
      spaceBetween: 50,
    },
  },
  spaceBetween: 30,
  a11y: {
    prevSlideMessage: "Previous slide",
    nextSlideMessage: "Next slide",
  },
  grabCursor: false,
  draggable: false,
});

window.addEventListener("load", function () {
  news_swiper.update(); // This will adjust the container height after content is loaded
});

var galleryThumbs = new Swiper(".gallery-thumbs-carousel", {
  spaceBetween: 12,
  slidesPerView: 2.25,
  freeMode: true,
  watchSlidesProgress: true,
  breakpoints: {
    768: {
      slidesPerView: 4
    },
    1280: {
      slidesPerView: 4
    }
  }
});

new Swiper(".gallery-carousel", {
  spaceBetween: 12,
  navigation: {
    nextEl: ".swiper-button-next-custom",
    prevEl: ".swiper-button-prev-custom"
  },
  thumbs: {
    swiper: galleryThumbs
  }
});
